import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M49.57 16.7007L35.77 2.71754C35.0394 1.95663 34.1651 1.35169 33.1989 0.938589C32.2326 0.525484 31.1942 0.312622 30.145 0.312622C29.0959 0.312622 28.0574 0.525484 27.0911 0.938589C26.1249 1.35169 25.2506 1.95663 24.52 2.71754L10.6825 16.7007L30.07 26.4776L49.57 16.7007ZM54.865 22.3471L57.6025 25.1134C58.3602 25.8489 58.963 26.7316 59.3748 27.7083C59.7866 28.6851 59.9989 29.7359 59.9989 30.7976C59.9989 31.8593 59.7866 32.9101 59.3748 33.8869C58.963 34.8637 58.3602 35.7463 57.6025 36.4818L35.55 58.9863C35.0273 59.5266 34.4189 59.9749 33.75 60.3126V33.0334L54.865 22.3471ZM5.13251 22.3471L2.39501 25.1134C1.63733 25.8489 1.0345 26.7316 0.62271 27.7083C0.210921 28.6851 -0.00134277 29.7359 -0.00134277 30.7976C-0.00134277 31.8593 0.210921 32.9101 0.62271 33.8869C1.0345 34.8637 1.63733 35.7463 2.39501 36.4818L24.45 58.9863C24.975 59.5269 25.5825 59.9766 26.25 60.3126V33.0334L5.13251 22.3471Z"
        fill="#7620FF"
      />
    </svg>
  );
}

export default SvgComponent;
