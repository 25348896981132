import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="69"
      height="61"
      viewBox="0 0 69 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.5 50.6585V49.1585H25H1.5V2.15845H66.8335V49.1587L43.3334 49.1585L41.8334 49.1585V50.6585V55.6585V57.1585H43.3334H47.6667V59.1584H20.6667V57.1585H25H26.5V55.6585V50.6585ZM38.3333 57.1586H39.8333V55.6586V50.6587V49.1587H38.3333H30H28.5V50.6587V55.6586V57.1586H30H38.3333Z"
        fill="black"
      />
      <path
        d="M26.5 50.6585V49.1585H25H1.5V2.15845H66.8335V49.1587L43.3334 49.1585L41.8334 49.1585V50.6585V55.6585V57.1585H43.3334H47.6667V59.1584H20.6667V57.1585H25H26.5V55.6585V50.6585ZM38.3333 57.1586H39.8333V55.6586V50.6587V49.1587H38.3333H30H28.5V50.6587V55.6586V57.1586H30H38.3333Z"
        fill="#F2B300"
      />
      <path
        d="M26.5 50.6585V49.1585H25H1.5V2.15845H66.8335V49.1587L43.3334 49.1585L41.8334 49.1585V50.6585V55.6585V57.1585H43.3334H47.6667V59.1584H20.6667V57.1585H25H26.5V55.6585V50.6585ZM38.3333 57.1586H39.8333V55.6586V50.6587V49.1587H38.3333H30H28.5V50.6587V55.6586V57.1586H30H38.3333Z"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M45.101 16.3909L41.5657 19.9263L47.2979 25.6587L41.5657 31.3909L45.101 34.9263L54.3689 25.6587L45.101 16.3909Z"
        fill="black"
      />
      <path
        d="M26.7678 19.9263L23.2325 16.3909L13.9646 25.6587L23.2325 34.9263L26.7678 31.3909L21.0355 25.6587L26.7678 19.9263Z"
        fill="black"
      />
      <path
        d="M34.5943 11.4919L28.6293 39.8253H33.7393L39.7042 11.4919H34.5943Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgComponent;
