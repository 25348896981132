import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="91"
      height="60"
      viewBox="0 0 91 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3" y="21" width="21" height="32" fill="#F40051" />
      <rect x="74" y="35" width="15" height="21" fill="#F40051" />
      <path
        d="M28.7356 45.7787H41.4262V48.7304H28.7356V51.1636H68.6487V48.7304H53.8523V45.7787H70.3091V39.0392H28.7356V45.7787Z"
        fill="black"
      />
      <path
        d="M17.6831 5.22703H78.1883V29.6111H83.3726V0H12.4988V16.3293H17.6831V5.22703Z"
        fill="black"
      />
      <path
        d="M0 58.9028H27.0641V17.9696H0V58.9028ZM14.1032 56.8473C13.3839 56.8473 12.7987 56.2611 12.7987 55.534C12.7987 54.8087 13.3839 54.2206 14.1032 54.2206C14.8244 54.2206 15.4058 54.8068 15.4058 55.534C15.4058 56.2611 14.8244 56.8473 14.1032 56.8473ZM3.91342 21.9152H23.1544L23.1525 52.1125H3.91342V21.9152Z"
        fill="black"
      />
      <path
        d="M72.1465 31.1837V60H90.9998V31.1837H72.1465ZM81.9561 32.3749C82.1816 32.3749 82.3661 32.5647 82.3661 32.7902C82.3661 33.0175 82.1816 33.2073 81.9561 33.2073C81.7269 33.2073 81.5406 33.0175 81.5406 32.7902C81.5406 32.5628 81.725 32.3749 81.9561 32.3749ZM79.5074 33.7578H84.3955V34.1561H79.5074V33.7578ZM81.7921 58.7562C81.2498 58.7562 80.8156 58.3128 80.8156 57.7698C80.8156 57.2249 81.2498 56.7853 81.7921 56.7853C82.3307 56.7853 82.7705 57.2249 82.7705 57.7698C82.7705 58.3109 82.3307 58.7562 81.7921 58.7562ZM88.2735 55.363C83.8606 55.3874 77.0625 55.4043 74.8766 55.3855V35.5728L88.2735 35.5709V55.363Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgComponent;
