import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="88"
      height="61"
      viewBox="0 0 88 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M72.3474 25.8493C71.8365 25.7329 71.3701 25.4718 71.0037 25.0973C70.6373 24.7228 70.3866 24.2507 70.2815 23.7374C68.9724 17.4852 66.1139 12.121 61.8986 8.05565C56.9923 3.33896 50.4419 0.718552 43.6362 0.750005C37.585 0.750005 32.0044 2.74261 27.4976 6.51134C24.42 9.11074 21.9722 12.3744 20.3385 16.0568C20.1623 16.4518 19.8947 16.7992 19.5577 17.0704C19.2207 17.3416 18.8241 17.5288 18.4005 17.6164C13.7982 18.5744 9.69541 20.5721 6.60849 23.3897C2.28238 27.3203 0 32.6964 0 38.9316C0 45.068 2.45112 50.6725 6.90167 54.714C11.1886 58.6071 16.9704 60.7497 23.1817 60.7497H67.4997C73.0173 60.7497 77.7661 59.2889 81.2365 56.5276C85.1859 53.3827 87.2723 48.714 87.2723 43.0225C87.2723 33.2095 80.1132 27.5828 72.3474 25.8493ZM56.1202 24.3255L37.7453 46.1435C37.4937 46.4422 37.1808 46.6834 36.8279 46.8507C36.475 47.0181 36.0903 47.1076 35.6998 47.1134H35.6538C35.2721 47.1135 34.8946 47.0334 34.5458 46.8784C34.197 46.7233 33.8846 46.4968 33.6288 46.2134L25.805 37.5424C24.8215 36.4532 24.8129 34.7589 25.8561 33.7243C26.118 33.464 26.43 33.2595 26.7733 33.1234C27.1165 32.9872 27.4839 32.9222 27.853 32.9322C28.2221 32.9423 28.5854 33.0272 28.9207 33.1818C29.256 33.3364 29.5565 33.5575 29.8038 33.8317L35.5822 40.2373L51.9458 20.8056C52.1766 20.5316 52.459 20.3058 52.7771 20.141C53.0952 19.9761 53.4426 19.8756 53.7995 19.845C54.1564 19.8145 54.5159 19.8545 54.8573 19.9629C55.1988 20.0713 55.5155 20.2458 55.7895 20.4766C56.0635 20.7074 56.2893 20.9899 56.4541 21.3079C56.619 21.626 56.7195 21.9734 56.7501 22.3303C56.7806 22.6873 56.7405 23.0467 56.6322 23.3882C56.5238 23.7296 56.3493 24.0464 56.1185 24.3203L56.1202 24.3255Z"
        fill="#4CD5C5"
      />
    </svg>
  );
}

export default SvgComponent;
