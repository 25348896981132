import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.051 27.5447V25.5L41.8317 25.5002V28.4932C43.8699 28.9643 45.8467 29.9419 47.8636 31.703C45.853 33.4293 43.8422 34.6871 41.8317 35.3902V38.3236H35.3511C36.529 41.3967 38.6995 43.9216 41.6248 46.1221H44.4148V48H15.4679V46.122H18.0603C20.6019 43.92 22.7792 41.3918 24.0128 38.3236H18.051V35.01C14.2149 34.1206 10.6283 31.4449 9 27.5447H18.051Z"
        fill="#7620FF"
      />
      <path
        d="M37.5385 19.7246L43.5761 10.5L41.8777 17.7515L47.6809 14.7732L43.9067 19.9852L49.5 20.1347L39.4663 24L39.4489 21.7662L34.5 23.9952L36.8868 17.244L37.5385 19.7246Z"
        fill="#F2B300"
      />
      <path
        d="M22.6842 7.45705L26.0015 6L31.7873 11.5469L34 18.6615L28.8878 20.9059L25.7357 15.9024L7.30248 24L6 21.1806L24.4334 13.0862L22.6842 7.45705Z"
        fill="#F40051"
      />
      <path
        d="M5.51924 15.866L30 1.73205L54.4808 15.866V44.134L30 58.2679L5.51924 44.134V15.866Z"
        stroke="#4CD5C5"
        strokeWidth="3"
      />
    </svg>
  );
}

export default SvgComponent;
